import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0f2f0a3c = () => interopDefault(import('../src/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _179a727d = () => interopDefault(import('../src/pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _158268a5 = () => interopDefault(import('../src/pages/inquire/index.vue' /* webpackChunkName: "pages/inquire/index" */))
const _d758ee16 = () => interopDefault(import('../src/pages/press/index.vue' /* webpackChunkName: "pages/press/index" */))
const _26871176 = () => interopDefault(import('../src/pages/stories/index.vue' /* webpackChunkName: "pages/stories/index" */))
const _882c2cf0 = () => interopDefault(import('../src/pages/stories/search/index.vue' /* webpackChunkName: "pages/stories/search/index" */))
const _23b1d208 = () => interopDefault(import('../src/pages/stories/event-category/_category.vue' /* webpackChunkName: "pages/stories/event-category/_category" */))
const _01a61fb6 = () => interopDefault(import('../src/pages/events/_id.vue' /* webpackChunkName: "pages/events/_id" */))
const _29f45406 = () => interopDefault(import('../src/pages/stories/_slug.vue' /* webpackChunkName: "pages/stories/_slug" */))
const _2ee6e501 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2d3043b9 = () => interopDefault(import('../src/pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _0f2f0a3c,
    name: "about"
  }, {
    path: "/events",
    component: _179a727d,
    name: "events"
  }, {
    path: "/inquire",
    component: _158268a5,
    name: "inquire"
  }, {
    path: "/press",
    component: _d758ee16,
    name: "press"
  }, {
    path: "/stories",
    component: _26871176,
    name: "stories"
  }, {
    path: "/stories/search",
    component: _882c2cf0,
    name: "stories-search"
  }, {
    path: "/stories/event-category/:category?",
    component: _23b1d208,
    name: "stories-event-category-category"
  }, {
    path: "/events/:id",
    component: _01a61fb6,
    name: "events-id"
  }, {
    path: "/stories/:slug",
    component: _29f45406,
    name: "stories-slug"
  }, {
    path: "/",
    component: _2ee6e501,
    name: "index"
  }, {
    path: "/:slug",
    component: _2d3043b9,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
