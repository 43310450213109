export const Announcement = () => import('../../src/components/Announcement.vue' /* webpackChunkName: "components/announcement" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../src/components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Halls = () => import('../../src/components/Halls.vue' /* webpackChunkName: "components/halls" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../src/components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../src/components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const PressMentions = () => import('../../src/components/PressMentions.vue' /* webpackChunkName: "components/press-mentions" */).then(c => wrapFunctional(c.default || c))
export const ShareButton = () => import('../../src/components/ShareButton.vue' /* webpackChunkName: "components/share-button" */).then(c => wrapFunctional(c.default || c))
export const ShareImage = () => import('../../src/components/ShareImage.vue' /* webpackChunkName: "components/share-image" */).then(c => wrapFunctional(c.default || c))
export const StoryGallery = () => import('../../src/components/StoryGallery.vue' /* webpackChunkName: "components/story-gallery" */).then(c => wrapFunctional(c.default || c))
export const StoryLayout1 = () => import('../../src/components/StoryLayout1.vue' /* webpackChunkName: "components/story-layout1" */).then(c => wrapFunctional(c.default || c))
export const StoryLayout2 = () => import('../../src/components/StoryLayout2.vue' /* webpackChunkName: "components/story-layout2" */).then(c => wrapFunctional(c.default || c))
export const StoryLayout3 = () => import('../../src/components/StoryLayout3.vue' /* webpackChunkName: "components/story-layout3" */).then(c => wrapFunctional(c.default || c))
export const StoryLayout4 = () => import('../../src/components/StoryLayout4.vue' /* webpackChunkName: "components/story-layout4" */).then(c => wrapFunctional(c.default || c))
export const SvgImage = () => import('../../src/components/SvgImage.vue' /* webpackChunkName: "components/svg-image" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
